<template>
  <div id="trend-chart-compare" style="height:300px;"></div>
</template>

<script>
import { setAxisMaxMinV2 } from "@/views/accountOverview/tool";
export default {
  props: {
    colors: {
      type: Array,
      default: () => []
    },
    chartData: {
      type: Object,
      default: () => {}
    },
    // x坐标轴
    xAxisData: {
      type: Array,
      default: () => []
    },
    fields: {
      type: Array,
      default: () => []
    },
    mark: {
      type: String,
      default: () => ""
    }
  },
  data() {
    return {
      myChart: {},
      chartOption: {
        xAxis: {},
        yAxis: [],
        series: []
      }
    };
  },
  computed: {
    chartChangeParams() {
      return {
        data: this.chartData,
        xAxisData: this.xAxisData,
        fields: this.fields
      };
    }
  },
  methods: {
    updateChart() {
      this.myChart = this.$echarts.init(
        document.getElementById("trend-chart-compare")
      );
      this.myChart.setOption(this.chartOption, true);
      setAxisMaxMinV2(this.myChart, this.chartOption, "yAxis", 6);
      this.myChart.setOption(this.chartOption, true);
      // console.log("last-this.chartOption", this.chartOption);
    },
    createTooltip(data, mark) {
      let obj = {
        trigger: "axis",
        extraCssText: `background-color:#fff;color:${this.colors[2]};box-shadow: rgba(0, 0, 0, 0.05) 0 9px 28px 8px, rgba(0, 0, 0, 0.08) 0 6px 16px 0, rgba(0, 0, 0, 0.12) 0 3px 6px -4px;border-radius: 2px;border: 1px solid #e5e6e9;`,
        formatter: params => {
          // console.log("tooltip-params", params);
          let title = params[0].axisValueLabel;
          let curIndex = data.origin.data.findIndex(
            item => item[mark] == title
          );
          let chainVal =
            curIndex != -1 ? data.target.data[curIndex][mark] : "无对应值";
          let result = "";
          result += `<p>${title}<p>`;
          params.forEach((item, i) => {
            result += `<p><span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${
              this.colors[i]
            }"></span>${item.seriesName}（${
              i == 0 ? item.axisValueLabel : chainVal
            }）：${item.data ? item.data : "--"}</p>`;
          });
          return result;
        }
      };
      return obj;
    },
    createxAxis(data, xAxisData, mark) {
      // console.log("createxAxis(data, xAxisData)", data, xAxisData);
      let interval = "";
      if (
        (data.origin && data.origin.data.length) ||
        (data.target && data.target.data.length)
      ) {
        interval = "auto";
      } else {
        interval = parseInt(xAxisData.length / 8);
      }
      let obj = {
        type: "category",
        boundaryGap: true,
        alignWithLabel: true,
        axisLabel: {
          color: this.colors[2],
          interval: interval,
          rotate: mark == "week" ? (xAxisData.length > 10 ? -10 : 0) : 0 //分周时防止刻度过多时标签重叠
        },
        axisLine: {
          lineStyle: {
            color: this.colors[2]
          }
        },
        data: xAxisData
      };
      return obj;
    },
    createyAxis(data, fields) {
      let obj = {
        name: fields[0].name,
        nameLocation: "middle",
        nameGap: 70,
        axisLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        axisLabel: {
          formatter: function(param) {
            return param.toFixed(2);
          }
        }
      };
      return obj;
    },
    createSeries(data, xAxisData, fields, mark) {
      let ydata1 = [],
        ydata2 = [],
        currentDatas = data.origin ? data.origin.data : [],
        chainDatas = data.target ? data.target.data : [];
      if (currentDatas.length) {
        ydata1 = currentDatas.map(item =>
          item[fields[0].keyName] ? item[fields[0].keyName] : 0
        );
      } else {
        ydata1 = xAxisData.map(item => 0);
      }
      if (chainDatas.length) {
        ydata2 = chainDatas.map(item =>
          item[fields[0].keyName] ? item[fields[0].keyName] : 0
        );
      } else {
        ydata2 = xAxisData.map(item => 0);
      }
      let arr = [
        {
          symbol: "none",
          name: fields[0].name,
          type: "line",
          data: ydata1,
          itemStyle: {
            normal: {
              lineStyle: {
                color: this.colors[0]
              }
            }
          }
        },
        {
          symbol: "none",
          name: fields[0].name,
          type: "line",
          data: ydata2,
          itemStyle: {
            normal: {
              lineStyle: {
                color: this.colors[1]
              }
            }
          }
        }
      ];
      if (currentDatas.length == 1) {
        // console.log("currentDatas.length==1");
        arr.forEach(item => {
          delete item.symbol;
        });
      }
      return arr;
    },
    renderChart(data, xAxisData, fields, mark) {
      this.chartOption.grid = {
        left: 50,
        bottom: 50,
        top: 50,
        right: 50,
        containLabel: true
      };
      let currentDatas = data.origin ? data.origin.data : [],
        chainDatas = data.target ? data.target.data : [];
      this.chartOption.tooltip = this.createTooltip(data, mark);
      this.chartOption.color = this.colors;
      this.chartOption.xAxis = this.createxAxis(data, xAxisData, mark);
      this.chartOption.yAxis = this.createyAxis(data, fields);
      this.chartOption.series = this.createSeries(
        data,
        xAxisData,
        fields,
        mark
      );
      // console.log("this.trend-chart-compareOption", this.chartOption);
      this.updateChart();
    }
  },
  mounted() {
    //图表初始化
    this.renderChart(this.chartData, this.xAxisData, this.fields, this.mark);
  },
  watch: {
    chartChangeParams: {
      handler(obj) {
        // console.log("trend-chart-compareChangeParams-obj");
        // 图表初始化
        this.renderChart(obj.data, obj.xAxisData, obj.fields, this.mark);
      },
      deep: true
    }
  }
};
</script>

<style lang='scss'>
</style>