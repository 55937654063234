<template>
  <div class="compare-wrap dp-flex align-item-c">
    <date-picker
      :initDateRange="initDateRange"
      @change-date="handleChangeCurrentDate"
    ></date-picker>
    <!-- 对比 -->
    <!-- 只有趋势分析有时间对比 -->
    <template v-if="activeTab == 'trend'">
      <el-checkbox v-model="dateCompare" @change="handleCompareChange"
        >对比</el-checkbox
      >
      <template v-if="dateCompare">
        <el-date-picker
          class="chain-date"
          v-model="chainStart"
          type="date"
          size="mini"
          placeholder="选择日期"
          :picker-options="pickerOptions"
          :clearable="false"
          @change="handleChangeChainStart"
        >
        </el-date-picker>
        <div class="el-input--mini ">
          ~<span>{{ chainEnd }}</span>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import datePicker from "@/views/components/datePicker";
import { fnCSTToYmd } from "@/utils/utils";
export default {
  components: { datePicker },
  props: {
    activeTab: {
      type: String,
      default: () => ""
    },
    initDateRange: {
      type: Array,
      default: () => []
    },
    currentDateRange: {
      type: Array,
      default: () => []
    },
    isCompare: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      dateCompare: false,
      chainStart: "",
      chainEnd: "",
      pickerOptions: {
        disabledDate: this.disabledDate
      }
    };
  },
  methods: {
    handleChangeCurrentDate(val) {
      this.chainStart = this.chainDateRange(val)[0];
      this.chainEnd = this.chainDateRange(val)[1];
      this.$emit("change-current-date", val);
    },
    ymdToMS(ymd) {
      let _ymd = ymd.replace(new RegExp("-", "gm"), "/");
      return new Date(_ymd).getTime();
    },
    chainDateRange(currentDateRange) {
      let start = this.ymdToMS(currentDateRange[0]),
        end = this.ymdToMS(currentDateRange[1]),
        dif = end - start; //相差毫秒数
      let chainEnd = start - 24 * 3600 * 1000,
        chainStart = chainEnd - dif;
      let arr = [
        fnCSTToYmd(new Date(chainStart), { tag: 1 }),
        fnCSTToYmd(new Date(chainEnd), { tag: 1 })
      ];
      return arr;
    },
    disabledDate(time) {
      let num;
      let start = this.ymdToMS(this.currentDateRange[0]),
        end = this.ymdToMS(this.currentDateRange[1]),
        dif = end - start; //相差毫秒数
      num = start - dif - 24 * 3600 * 1000;
      return time.getTime() > num;
    },
    handleChangeChainStart(val) {
      let start = this.ymdToMS(this.currentDateRange[0]),
        end = this.ymdToMS(this.currentDateRange[1]),
        dif = end - start; //相差毫秒数
      this.chainEnd = fnCSTToYmd(new Date(val.getTime() + dif), { tag: 1 });
      this.$emit(
        "post-chain-start",
        fnCSTToYmd(new Date(val.getTime()), { tag: 1 })
      );
      this.$bus.$emit("chain-start-change")
    },
    handleCompareChange(val) {
      this.$emit("post-compare-status", val);
    }
  },
  watch: {
    currentDateRange: {
      handler(val) {
        if (val && val.length) {
          this.chainStart = this.chainDateRange(val)[0];
          this.chainEnd = this.chainDateRange(val)[1];
        }
      },
      deep: true,
      immediate: true
    },
    isCompare: {
      handler(val) {
        this.dateCompare = val;
      }
    }
  }
};
</script>

<style lang="scss">
@import "@/styles/commonly";
.compare-wrap {
  .el-date-editor {
    width: 230px;
  }
  .el-checkbox {
    margin: 0 8px;
  }
  .chain-date.el-date-editor {
    width: 110px;
    .el-input__inner {
      padding-right: 0;
    }
  }
}
</style>